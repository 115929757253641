import Section from '../section';

const Where = ({ doc, closeCallback, lang }) => {
  const colors = {
    '--bg': 'var(--one)',
    '--fg': 'var(--two)'
  };

  return (
    <Section
      colors={colors}
      doc={doc}
      closeCallback={closeCallback}
      lang={lang}
      dir='left'
    />
  );
};

export default Where;

