import { useCallback, useEffect, useState } from 'react';
import { PrismicRichText, useSinglePrismicDocument } from '@prismicio/react'
import { AnimatePresence, motion } from 'framer-motion';
import { LiveChatWidget } from '@livechat/widget-react';

import Navigation from '../../components/navigation';
import Stickers from '../../components/stickers';
import Marquee from '../../components/marquee';
import ChatBox from '../../components/chatbox';
import Where from '../../components/where';
import What from '../../components/what';

import style from './landing.module.css';

const ModalButton = ({ callback, text }) => {
  return (
    <button onClick={callback} className={style.button}>
      {text}
    </button>
  );
};

const Landing = ({ lang }) => {
  const [modal, setModal] = useState(null);

  const [whereDoc] = useSinglePrismicDocument('where_description', { lang });
  const [whatDoc] = useSinglePrismicDocument('what', { lang });
  const [marqueeDoc] = useSinglePrismicDocument('marquee', { lang });

  const whereIndex = 1;
  const whatIndex  = 2;

  function handleModalButton(i) {
    return () => { setModal(i); };
  };

  function closeModalCallback() {
    setModal(null);
  };

  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  };

  const handleEsc = useCallback((ev) => {
    if (ev.key === 'Escape') {
      if (modal)
        setModal(null);
    }
  }, [modal]);

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [modal]);

  return (
      <main className={style.landing}>
        <Navigation lang={lang}/>

        <LiveChatWidget
          license="12690717"
          onNewEvent={handleNewEvent}
        />

        <AnimatePresence exitBeforeUnmount>
          { !whereDoc && !whatDoc &&
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className={style.blackout}></motion.div>
          }
          { modal === whereIndex &&
            <Where doc={whereDoc} lang={lang} closeCallback={closeModalCallback} />
          }
          { modal === whatIndex &&
            <What doc={whatDoc} lang={lang} closeCallback={closeModalCallback} />
          }
        </AnimatePresence>

        <ChatBox lang={lang} />

        <section className={style.look} onClick={handleModalButton(whereIndex)}>
          { whereDoc &&
            <motion.div
              initial={{ opacity: 0, y: -300, scale: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{ duration: 1.0, type: 'spring', bounce: 0.4 }}
              className={style.hero}>
              <PrismicRichText field={whereDoc.data.hero_title}
                components={{
                  heading1: ({ children }) => <h1 className={style.title}>
                    {children}
                  </h1>
                }}/>
              <span className={style.question_mark}>?</span>
              <ModalButton text={whereDoc.data.button_text}
                callback={handleModalButton(whatIndex)} />
            </motion.div>
          }
        </section>

        <section className={style.do} onClick={handleModalButton(whatIndex)}>
          { whatDoc &&
            <motion.div
              initial={{ opacity: 0, y: -300, scale: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              transition={{ duration: 1.0, type: 'spring', bounce: 0.4 }}
              className={style.hero}>
              <PrismicRichText field={whatDoc.data.hero_title}
                components={{
                  heading1: ({ children }) => <h1 className={style.title}>
                    {children}
                  </h1>
                }}/>
              <span className={style.question_mark}>?</span>
              <ModalButton text={whatDoc.data.button_text}
                callback={handleModalButton(whatIndex)} />
            </motion.div>
          }
        </section>

        <Stickers />

        { marqueeDoc &&
          <Marquee
            speed={marqueeDoc.data.speed}
            text={marqueeDoc.data.text.split(' ')} />
        }
      </main>
  );
};

export default Landing;

