import { usePrismicDocumentsByTag } from '@prismicio/react';

import Section from '../section';

const What = ({ doc, closeCallback, lang }) => {
  const [resources] = usePrismicDocumentsByTag('Hvað get ég gert', { lang });

  const colors = {
    '--bg': 'var(--two)',
    '--fg': 'var(--one)'
  };

  return (
    <Section
      colors={colors}
      doc={doc}
      closeCallback={closeCallback}
      resources={resources}
      lang={lang}
      dir='right'
    />
  );
};

export default What;

