import { PrismicRichText, useSinglePrismicDocument } from '@prismicio/react';
import { motion } from 'framer-motion';

import style from './infobox.module.css';

import i1717 from './img/logo1717.png';
import iembl from './img/embl.png';
import iheil from './img/heilbr.png';
import ifela from './img/felags.png';
import iment from './img/mennta.png';
import inysk from './img/nyskopun.png';

const Infobox = ({ lang }) => {
  const [infoDoc] = useSinglePrismicDocument('infobox', { lang });

  return (
    <motion.aside
      initial={{ originX: 1, scaleX: 0 }}
      animate={{ originX: 1, scaleX: 1 }}
      exit={{ originX: 1, scaleX: 0, transition: {
        delay: 0.1, duration: 0.4, bounce: 0.2, type: 'spring'
      }}}
      transition={{ type: 'spring', duration: 0.5, bounce: 0.2 }}
      className={style.box}>
      <motion.div
        initial={{ y: 50, rotate: 45 }}
        animate={{ y: 0, rotate: 45 }}
        exit={{ y: 50, rotate: 45, transition: { delay: 0.0 } }}
        transition={{ type: 'tween', delay: 0.2, duration: 0.2 }}
        className={style.marker}></motion.div>

      { infoDoc && <PrismicRichText field={infoDoc.data.title}/> }
      { infoDoc && <PrismicRichText field={infoDoc.data.description}/> }

      <div className={style.logos}>
        <div className={style.topRow}>
          <img src={i1717} alt="1717" />
          <img src={iembl} alt="Embætti Landlæknis" />
          <img src={ifela} alt="Félags- og vinnumarkaðsráðuneytið" />
        </div>
        <div className={style.botRow}>
          <img src={inysk} alt="Háskóla-, iðnaðar- og nýsköpunarráðuneytið" />
          <img src={iment} alt="Mennta- og barnamálaráðuneytið" />
          <img src={iheil} alt="Heilbrigðisráðuneytið" />
        </div>
      </div>
    </motion.aside>
  );
};

export default Infobox;

