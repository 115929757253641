import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import style from './navigation.module.css';

import Infobox from '../infobox';

import { ReactComponent as LangIcon } from './img/language.svg';
import { ReactComponent as InfoIcon } from './img/info.svg';

const Navigation = ({ lang }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  const handleEsc = useCallback((ev) => {
    if (ev.key === 'Escape') {
      if (infoOpen)
        setInfoOpen(false);
    }
  }, [infoOpen]);

  useEffect(() => {
    document.addEventListener('keydown', handleEsc, false);

    return () => {
      document.removeEventListener('keydown', handleEsc, false);
    };
  }, [infoOpen]);

  const toggleInfo = (ev) => {
    ev.preventDefault();
    setInfoOpen(!infoOpen);
  };

  return (
    <div className={style.container}>
      <AnimatePresence exitBeforeUnmount>
        { infoOpen && <Infobox lang={lang}/>}
      </AnimatePresence>
      <button onClick={toggleInfo} className={style.info}>
        <InfoIcon />
      </button>
      <button className={style.lang}>
        <LangIcon />
        <ul className={style.dropdown}>
          <li>
            <Link className={style.link} to='/'>is</Link>
          </li>
          <li>
            <Link className={style.link} to='/en'>en</Link>
          </li>
        </ul>
      </button>
    </div>
  );
};

export default Navigation;

