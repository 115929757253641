import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'ertuokei'
const endpoint = prismic.getEndpoint(repositoryName)

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token
  accessToken: 'MC5ZYmlCZEJFQUFDQUFFdGJp.WRYV77-9MO-_vQgsa--_vRdUQu-_vQMjP--_ve-_vSTvv73vv70qe--_ve-_vW4VFe-_vTrvv70',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  // routes: [
  //   {
  //     type: 'link',
  //     path: '/:uid',
  //   },
  // ],
})
