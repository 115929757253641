import { useState, useRef } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { motion } from 'framer-motion';

import Resource from '../resource';

import style from './section.module.css';

import { ReactComponent as CloseIcon } from './img/close.svg';

const Section = ({ doc, dir, colors, closeCallback }) => {
  const [openResources, setResource] = useState([]);
  const modalRef = useRef();

  const mobile = window.innerWidth <= 500;

  let blackoutVar = {
    hidden: { opacity: 0, },
    shown: {
      opacity: 1,
      transition: { duration: 0.2 }
    },
    leave: {
      opacity: 0,
      transition: {
        delay: 0.3,
        duration: 0.2
      }
    }
  };

  let modalVar = {
    closed: {
      scaleX: 0,
      x: window.innerWidth * (dir === 'left' ? -1 : 1)
    },
    open: {
      scaleX: 1, x: 0,
      transition: {
        delay: 0.1,
        type: 'spring',
        duration: 0.65,
        bounce: 0.15
      }
    },
    leave: {
      x: window.innerWidth * (dir === 'left' ? -1 : 1),
      transition: {
        duration: 0.3,
        bounce: 0.2,
        delay: 0
      }
    }
  };

  let defaultVar = !mobile ? {
    closed: { scaleX: 0, y: -300 },
    open: {
      scaleX: 1, y: 0,
      transition: {
        type: 'spring',
        duration: 0.85,
        bounce: 0.4,
        delay: 0.4
      }
    },
    leave: {
      transition: {
        duration: 0.2,
        delay: 0
      }
    }
  } : { };

  let questionVar = !mobile ? {
    closed: { rotate: -40, scale: 0.5, y: -300 },
    open: {
      rotate: 0, scale: 1, y: 0,
      transition: {
        type: 'spring',
        duration: 0.6,
        bounce: 0.4,
        delay: 0
      }
    },
    leave: {
      transition: {
        duration: 0.2,
        delay: 0
      }
    }
  } : { };

  let resourceVar = !mobile ? {
    closed: { x: 100, opacity: 0 },
    open: {
      x: 0, opacity: 1,
      transition: {
        type: 'spring',
        duration: 0.6,
        bounce: 0.4,
        delay: 0
      }
    },
    leave: {
      transition: {
        duration: 0.2,
        delay: 0
      }
    }
  } : { };

  const openCallback = (i) => () => {
    if (openResources.includes(i)) {
      let l = openResources.slice();
      l.splice(l.indexOf(i), 1);
      setResource(l);
    } else {
      setResource([...openResources, i]);
    }
  };

  const resourceMap = (r, i) => {
    return (
      <Resource key={i} title={r.title[0].text} delay={i * 0.05}
        openCallback={openCallback(i)}
        content={<PrismicRichText field={r.description}/>}
        open={openResources.includes(i)}/>
    );
  };

  if (!doc) return null;

  return (
    <motion.div
      style={colors}
      key='wrapper'>
      <motion.div key='blackout'
        initial='hidden'
        animate='shown'
        exit='leave'
        variants={blackoutVar}
        className={style.blackout}>
      </motion.div>
      <motion.article key='where'
        ref={modalRef}
        initial='closed'
        animate='open'
        exit='leave'
        variants={modalVar}
        className={style.container}>
        <button
          onClick={closeCallback}
          className={style.close}>
          <span className={style.text}>Loka</span>
          <CloseIcon />
        </button>
        <motion.h1
          key='title'
          variants={defaultVar}
          className={style.title}>
          {doc.data.title[0].text}
          <motion.span
            key='question'
            variants={questionVar}
            className={style.question_mark}>
            ?
          </motion.span>
        </motion.h1>
        <motion.div
          key='content'
          variants={defaultVar}
          className={style.content}>
          <aside className={style.information}>
            <PrismicRichText field={doc.data.information}/>
          </aside>
          <motion.div
            key='resources'
            variants={resourceVar}
            className={style.resources}>
            { doc.data.body[0].items.map(resourceMap) }
          </motion.div>
        </motion.div>
      </motion.article>
    </motion.div>
  );
};

export default Section;

