import style from './stickers.module.css';

import img_ok      from './img/OK.png';
import img_balloon from './img/bladra.png';
import img_fish    from './img/fiskur.png';
import img_frown   from './img/fylukall.png';
import img_hand    from './img/hendi.png';
import img_ice     from './img/ice.png';
import img_italian from './img/italinn.png';
import img_melt    from './img/melti.png';
import img_okeii   from './img/okeii.png';
import img_puppet  from './img/puppetmaster.png';
import img_hotdog  from './img/pylsa.png';
import img_seam    from './img/saumur.png';
import img_slay    from './img/slay.png';
import img_smiley  from './img/smiley.png';
import img_mirror  from './img/speglun.png';
import img_typo    from './img/typograph.png';
import img_uuuok   from './img/uuuok.png';
import img_water   from './img/water.png';

import { ReactComponent as Stars1 } from './img/stars1.svg';
import { ReactComponent as Stars2 } from './img/stars2.svg';

const stickerList = [
  { cls: style.ok,      src: img_ok,      alt: 'OK' },
  { cls: style.balloon, src: img_balloon, alt: 'Blaðra' },
  { cls: style.fish,    src: img_fish,    alt: 'Fiskur' },
  { cls: style.frown,   src: img_frown,   alt: 'Fýlukall' },
  { cls: style.hand,    src: img_hand,    alt: 'Hendi' },
  { cls: style.ice,     src: img_ice,     alt: 'Ís' },
  { cls: style.italian, src: img_italian, alt: 'Ítalinn' },
  { cls: style.melt,    src: img_melt,    alt: 'Bráðna' },
  { cls: style.okeii,   src: img_okeii,   alt: 'Okeii!' },
  { cls: style.puppet,  src: img_puppet,  alt: 'Brúðan' },
  { cls: style.hotdog,  src: img_hotdog,  alt: 'Pylsa' },
  { cls: style.seam,    src: img_seam,    alt: 'Saumur' },
  { cls: style.slay,    src: img_slay,    alt: 'Yaas Queen!' },
  { cls: style.smiley,  src: img_smiley,  alt: 'Broskall' },
  { cls: style.mirror,  src: img_mirror,  alt: 'Spegill' },
  { cls: style.typo,    src: img_typo,    alt: 'Letur' },
  { cls: style.uuuok,   src: img_uuuok,   alt: 'Uuu... OK' },
  { cls: style.water,   src: img_water,   alt: 'Vatn' }
]

const Sticker = ({ cls, src, alt }) => {
  return (
    <img className={cls} src={src} alt={alt} />
  );
};


const Stickers = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.stars_wrapper}>
        <Stars1 className={style.stars}/>
        <Stars2 className={style.stars}/>
      </div>
      { stickerList && stickerList.map((s) => (
        <Sticker key={s.alt} {...s} />)
      )}
    </div>
  );
};

export default Stickers;

