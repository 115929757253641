import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PrismicProvider } from '@prismicio/react';
import { client } from './prismic';
import ReactGA from 'react-ga';

import Landing from './views/landing';

import './index.css';

ReactGA.initialize('UA-223930504-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <PrismicProvider client={client}>
      <Router>
        <Routes>
          <Route index element={<Landing lang='is'/>} />
          <Route path='/en' element={<Landing lang='en-us'/>} />
          <Route path='*' element={<Navigate to='/'/>} />
        </Routes>
      </Router>
    </PrismicProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

