import { motion } from 'framer-motion';

import style from './marquee.module.css';

import { ReactComponent as I1 } from './img/BatteryLow.svg';
import { ReactComponent as I2 } from './img/Bicycle.svg';
import { ReactComponent as I3 } from './img/Brain.svg';
import { ReactComponent as I4 } from './img/ChatCircleDots.svg';
import { ReactComponent as I5 } from './img/CloudRain.svg';
import { ReactComponent as I6 } from './img/Cookie.svg';
import { ReactComponent as I7 } from './img/Dog.svg';
import { ReactComponent as I8 } from './img/Ear.svg';
import { ReactComponent as I9 } from './img/EyeClosed.svg';
import { ReactComponent as I10 } from './img/Flower.svg';
import { ReactComponent as I11 } from './img/Ghost.svg';
import { ReactComponent as I12 } from './img/HandFist.svg';
import { ReactComponent as I13 } from './img/HandsClapping.svg';
import { ReactComponent as I14 } from './img/Headset.svg';
import { ReactComponent as I15 } from './img/Heart.svg';
import { ReactComponent as I16 } from './img/Lightbulb.svg';
import { ReactComponent as I17 } from './img/MusicNotes.svg';
import { ReactComponent as I18 } from './img/Moon.svg';
import { ReactComponent as I19 } from './img/PersonSimpleRun.svg';
import { ReactComponent as I20 } from './img/Phone.svg';
import { ReactComponent as I21 } from './img/Planet.svg';
import { ReactComponent as I22 } from './img/Robot.svg';
import { ReactComponent as I23 } from './img/SmileyMeh.svg';
import { ReactComponent as I24 } from './img/SmileyNervous.svg';
import { ReactComponent as I25 } from './img/SmileySad.svg';
import { ReactComponent as I26 } from './img/SmileyWink.svg';
import { ReactComponent as I27 } from './img/SmileyXEyes.svg';
import { ReactComponent as I28 } from './img/SoccerBall.svg';
import { ReactComponent as I29 } from './img/Sun.svg';
import { ReactComponent as I30 } from './img/ThumbsDown.svg';
import { ReactComponent as I31 } from './img/ThumbsUp.svg';

const icons = [
  <I1 />, <I2 />, <I3 />, <I4 />, <I5 />, <I6 />, <I7 />, <I8 />, <I9 />, <I10 />,
  <I11 />, <I12 />, <I13 />, <I14 />, <I15 />, <I16 />, <I17 />, <I18 />, <I19 />,
  <I20 />, <I21 />, <I22 />, <I23 />, <I24 />, <I25 />, <I26 />, <I27 />, <I28 />,
  <I29 />, <I30 />, <I31 />
];

const Marquee = ({ speed, text }) => {
  return (
    <div className={style.marquee_wrapper}>
      <div className={style.marquee}>
        <motion.div
          animate={{ x: '-50%' }}
          transition={{
            duration: speed ? speed : 150,
            type: 'tween',
            ease: 'linear'
          }}
          className={style.track} aria-hidden="true">
          { text && text.map((w, i) => (
            <div className={style.pair} key={i}>
            <span className={style.word}>{w}</span>
            { icons[Math.floor(Math.random() * icons.length)] }
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Marquee;

