import { AnimatePresence, motion } from 'framer-motion';

import style from './resource.module.css';

import { ReactComponent as Caret } from './img/caret.svg';

const Resource = ({ delay, openCallback, title, content, open=false }) => {
  const mobile = window.innerWidth <= 500;

  const boxVariants = !mobile ? {
    shown: {
      scale: 1, x: 0, y: 0, opacity: 1,
      transition: { delay, duration: 0.4 }
    },
    hidden: {
      scale: 0, x: 100, y: 200, opacity: 0,
      transition: { delay: 0, duration: 0.4 }
    }
  } : { };

  const toggleVariants = {
    open: {
      maxHeight: 320, overflowY: 'auto',
      transition: { delay: 0, duration: 0.3, ease: 'easeInOut' }
    },
    closed: {
      maxHeight: 0, overflowY: 'hidden',
      transition: { delay: 0, duration: 0.3, ease: 'easeInOut' }
    }
  };

  return (
    <motion.div key={title}
      initial='hidden' animate='shown'
      variants={boxVariants}
      className={style.container}>
      <button onClick={openCallback} className={open ? style.open : style.closed}>
        <Caret className={style.caret}/>
        <span>
          {title}
        </span>
      </button>
      <AnimatePresence exitBeforeUnmount>
        { open &&
          <motion.div key={'c_' + title}
            animate='open' initial='closed' exit='closed'
            variants={toggleVariants} className={style.content}>
          {content}
          </motion.div>
        }
      </AnimatePresence>
    </motion.div>
  );
};

export default Resource;

