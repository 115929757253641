import { useSinglePrismicDocument } from '@prismicio/react';
import { motion } from 'framer-motion';

import style from './chatbox.module.css';

import { ReactComponent as Phone } from './img/phone.svg';

const ChatBox = ({ lang }) => {
  const [chatboxDoc] = useSinglePrismicDocument('chatbox', { lang });

  const onClicker = (ev) => {
    console.log(window.LC_API);
    if (window.LC_API && window.LC_API.is_loaded())
      window.LC_API.open_chat_window();

    ev.preventDefault();
  };

  return (
      <motion.div
        initial={{ opacity: 0, y: -100, scale: 0 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ duration: 1.5, type: 'spring', bounce: 0.4 }}
        className={style.chat_box}>
        <div className={style.phones}>
          <h3><a href="tel:+3541717">1717</a></h3>
          <Phone className={style.phone} />
          <h3><a href="tel:+354112">112</a></h3>
        </div>
        <button onClick={onClicker} className={style.chat_btn}>
          { chatboxDoc && chatboxDoc.data.button_text}
          <svg viewBox="0 0 51 51" onClick={onClicker} aria-label='Hefja spjall'
            xmlns="http://www.w3.org/2000/svg" className={style.bubble}>
            <path d="M25.4579 4.88367C28.9859 4.88429 32.4529 5.80362 35.5177
              7.55117C38.5824 9.29872 41.1393 11.8143 42.9366 14.8501C44.7338 17.886
              45.7095 21.3376 45.7676 24.8651C45.8257 28.3926 44.9642 31.8745 43.2679
              34.9679L44.9388 40.809C45.0923 41.3449 45.0996 41.9121 44.96
              42.4519C44.8203 42.9916 44.5388 43.4841 44.1446 43.8783C43.7504 44.2725
              43.2579 44.554 42.7181 44.6937C42.1784 44.8333 41.6112 44.826 41.0753
              44.6725L35.2327 43.0062C32.5174 44.4963 29.4974 45.3459 26.4035
              45.4898C23.3095 45.6337 20.2237 45.0683 17.3818 43.8367C14.5399 42.6051
              12.0173 40.74 10.0068 38.3839C7.99631 36.0279 6.55119 33.2433 5.78192
              30.2431C5.01265 27.2429 4.93962 24.1065 5.5684 21.0737C6.19718 18.0409
              7.51112 15.1921 9.40977 12.745C11.3084 10.298 13.7415 8.31744 16.523
              6.95491C19.3044 5.59237 22.3606 4.8839 25.4579 4.88367H25.4579ZM34.8331
              27.5384C35.2966 27.5384 35.7498 27.4009 36.1352 27.1434C36.5206 26.8859
              36.821 26.5198 36.9984 26.0916C37.1758 25.6633 37.2222 25.192 37.1318
              24.7374C37.0414 24.2828 36.8181 23.8651 36.4904 23.5374C36.1626 23.2096
              35.745 22.9864 35.2903 22.8959C34.8357 22.8055 34.3644 22.8519 33.9362
              23.0293C33.5079 23.2067 33.1419 23.5071 32.8843 23.8925C32.6268 24.278
              32.4893 24.7311 32.4893 25.1946C32.4893 25.8162 32.7363 26.4124 33.1758
              26.8519C33.6153 27.2915 34.2115 27.5384 34.8331 27.5384V27.5384ZM25.4581
              27.5384C25.9216 27.5384 26.3748 27.4009 26.7602 27.1434C27.1456 26.8859
              27.446 26.5198 27.6234 26.0916C27.8008 25.6633 27.8472 25.192 27.7568
              24.7374C27.6664 24.2828 27.4431 23.8651 27.1154 23.5374C26.7876 23.2096
              26.37 22.9864 25.9153 22.8959C25.4607 22.8055 24.9894 22.8519 24.5612
              23.0293C24.1329 23.2067 23.7669 23.5071 23.5093 23.8925C23.2518 24.278
              23.1143 24.7311 23.1143 25.1946C23.1143 25.8162 23.3613 26.4124 23.8008
              26.8519C24.2403 27.2915 24.8365 27.5384 25.4581 27.5384ZM16.0831
              27.5384C16.5466 27.5384 16.9998 27.4009 17.3852 27.1434C17.7706 26.8859
              18.071 26.5198 18.2484 26.0916C18.4258 25.6633 18.4722 25.192 18.3818
              24.7374C18.2914 24.2828 18.0681 23.8651 17.7404 23.5374C17.4126 23.2096
              16.995 22.9864 16.5403 22.8959C16.0857 22.8055 15.6144 22.8519 15.1862
              23.0293C14.7579 23.2067 14.3919 23.5071 14.1343 23.8925C13.8768 24.278
              13.7393 24.7311 13.7393 25.1946C13.7393 25.8162 13.9863 26.4124 14.4258
              26.8519C14.8653 27.2915 15.4615 27.5384 16.0831 27.5384Z" fill="white"/>
            <motion.rect x='0' width='10' y='20' height='10' fill='white'
              animate={{
                width: [30, 30, 30, 30, 20, 20, 20, 20, 10, 10, 10, 10, 0, 0, 0, 0],
                x: [10, 10, 10, 10, 20, 20, 20, 20, 30, 30, 30, 30, 40, 40, 40, 40]
              }}
              transition={{ delay: 1.5, duration: 1.5, repeat: Infinity, repeatDelay: 1.5 }}
              />
          </svg>
        </button>
      </motion.div>
  );
};

export default ChatBox;

